import React, { Component } from "react";
import {
    Table,
    Space,
    Button,
    Popconfirm,
    Divider,
    Typography
} from 'antd';
import { withToastManager } from "react-toast-notifications";
import { companyService, userService, joinRequestsService } from "../../services";
const { Text, Title } = Typography;

class CompanyMembers extends Component {
    memberColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record, index) => text,/*  <Button type="link" onClick={() => this.props.history.push(`/manage/suppliers/${record.id}`)}>{text}</Button> */
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record, index) => text,
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            render: (text, record, index) => text,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (text, record, index) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.removeMember(record.id)}>
                        <Button type="primary" danger href="#">Remove</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    requestColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record, index) => text,/*  <Button type="link" onClick={() => this.props.history.push(`/manage/suppliers/${record.id}`)}>{text}</Button> */
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record, index) => text,
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            render: (text, record, index) => text,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (text, record, index) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.acceptRequest(record.id)}>
                        <Button type="primary" href="#">Accept</Button>
                    </Popconfirm>
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.rejectRequest(record.id)}>
                        <Button type="primary" danger href="#">Reject</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            members: [],
            requests: []
        };
    }

    componentWillMount() {
        if (!this.props.match.params.id) this.props.history.goBack();
        this.fetchData(this.props.match.params.id);
    }


    fetchData = async (companyId) => {
        console.log(companyId)

        let res = await userService.getUserData();
        if (res.isSuccess && companyId == null) {
            this.setState({ company: res.dto.company })
            companyId = res.dto.company.id;
        } else {

        }

        companyService.fetchMembers(companyId)
            .then(result => {
                if (result.isSuccess) {
                    let members = result.dto;
                    // debugger
                    members.forEach((member, index) => member.key = index);
                    this.setState({ members });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
        companyService.fetchRequests(companyId)
            .then(result => {
                if (result.isSuccess) {
                    // debugger
                    let rowRequests = result.dto;
                    let requests = new Array;
                    rowRequests.forEach((request, index) => {
                        requests.push({
                            id: request.id,
                            key: request.id,
                            name: request.user.firstName + ' ' + request.user.lastName,
                            email: request.user.email,
                            phoneNumber: request.user.phoneNumber
                        })
                    });
                    this.setState({ requests });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });


    }


    removeMember = (id) => {

    }
    rejectRequest = (id) => {
        joinRequestsService.rejectRequest(id).then(res => {
            if (res.isSuccess) {
                this.props.toastManager.add(res.message, { appearance: 'success', autoDismiss: true });
                window.location.reload(false);
            }
            else {
                this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
            }
        });

    }
    acceptRequest = (id) => {
        joinRequestsService.acceptRequest(id).then(res => {
            if (res.isSuccess) {
                this.props.toastManager.add(res.message, { appearance: 'success', autoDismiss: true });
                window.location.reload(false);
            }
            else {
                this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
            }
        });
    }

    render() {
        return (
            <div>


                <Title level={3} >{this.state.company ? this.state.company.name + " Members" : ""}</Title>

                <Divider />
                <h3>Members</h3>
                <Table size='small' columns={this.memberColumns} dataSource={this.state.members} bordered />
                <Divider />
                <h3>Pendding Requests</h3>
                <Table size='small' columns={this.requestColumns} dataSource={this.state.requests} bordered />
            </div >
        );
    }
}

export const CompanyMembersPage = withToastManager(CompanyMembers);
