import React from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  PageHeader,
  Select,
  Card,
  Switch,
  Checkbox,
  Divider,
  Typography
} from "antd";
import { ProfileService, companyService, authService } from "../../services";
import { withToastManager } from "react-toast-notifications";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import CompanyFrom from "./CompanyForm";

import { BehaviorSubject } from 'rxjs';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

const { Option } = Select;
const { Text, Title } = Typography;

class UserDataComponent extends React.Component {
  forms = {
    userProfile: null,
    companyProfile: null,
    companySummary: null
  };

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      companyOptions: [],
      companies: [],
      currentCompany: null,
      isCompanyProfile: false,
      fields: [],
      companySummaryFields: [],
      companyFields: [],
      modals: { companyForm: false },
      roles: authService.currentUserValue.roles,
      companyLogo: null,
      selectedCompanyId: null
    };
  }

  componentDidMount() {
    this.getProfileData();
    this.getAllCompanies();
  }

  getProfileData = () => {
    ProfileService.getProfile()
      .then(response => {
        if (response.isSuccess) {
          let profile = response.dto;
          console.log(profile);
          let companyLogo = null;
          let fields = new Array;
          let companyFields = new Array;
          let isCompanyProfile, currentCompany
          for (const [key, value] of Object.entries(profile)) {
            if (key == "address" && value) {
              for (const [key, value] of Object.entries(profile.address)) {
                fields.push({ name: key, value: value })
              }
            }
            else if (key == "company" && value) {
              for (const [key, value] of Object.entries(profile.company)) {
                companyFields.push({ name: key, value: value })
              }
            }
            else {
              fields.push({ name: key, value: value })
            }
          }
          if (profile.company) {
            isCompanyProfile = true;
            currentCompany = profile.company;
            companyLogo = profile.company.logo
            this.getCompanySummaryFields(currentCompany)
          }
          else if (profile.companyRequest != null) {
            isCompanyProfile = true;
            var selectedCompanyId = profile.companyRequest.companyId
            this.getCompanySummaryFields(profile.companyRequest.company);
          }
          else {
            profile.companyRequest = null
            isCompanyProfile = false;
            currentCompany = null;
          }
          this.setState({ profile, fields, isCompanyProfile, currentCompany, companyFields, companyLogo, selectedCompanyId });
        }
        else {
          this.props.toastManager.add(response.message, { appearance: 'error' });
        }
      });
  }

  getAllCompanies = () => {
    companyService.fetchAllCompanies().then(res => {
      let options = [];
      if (res.isSuccess) {
        res.dto.forEach((company, i) => {
          options.push({ label: company.name, value: company.id })
        });
      }
      this.setState({ companyOptions: options, companies: res.dto })
    });
  }


  getFieldsOneCol = (fields) => {
    const children = [];

    for (let i = 0; i < fields.length; i++) {
      var field = fields[i];
      children.push(
        <Col span={24} key={i}>
          <Form.Item
            hidden={field.hidden}
            name={field.name}
            label={field.label}
            rules={[
              {
                required: field.required,
                message: field.label + " is Required",
              },
            ]}

          >
            <Input
              placeholder={field.label}
              disabled={field.disabled} />
          </Form.Item>
        </Col>
      );
    }

    return children;
  };

  getFieldsTwoCol = (fields) => {
    const children = [];

    for (let i = 0; i < fields.length; i++) {
      var field = fields[i];
      children.push(
        <Col span={12} key={i}>
          <Form.Item
            hidden={field.hidden}
            name={field.name}
            label={field.label}
            rules={[
              {
                required: field.required,
                message: field.label + " is Required",
              },
            ]}

          >
            <Input
              placeholder={field.label}
              disabled={field.disabled} />
          </Form.Item>
        </Col>
      );
    }

    return children;
  };

  onSubmitUserProfile = async () => {

    let user = {};
    userFields.forEach(field => {
      user[field.name] = this.forms.userProfile.getFieldValue(field.name);
    });

    let address = {};
    addressFields.forEach(field => {
      address[field.name] = this.forms.userProfile.getFieldValue(field.name);
    });

    user.address = address;
    // user.company = company;
    user.isCompany = this.state.isCompanyProfile;
    let result = await ProfileService.updateProfile(user);

    if (!result.isSuccess) {
      this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
    }
    else {
      console.log("Update Success:", result.isSuccess)
      this.props.toastManager.add(result.message, { appearance: 'success', autoDismiss: true });
    }
  };



  onSubmitCompany = async (company) => {

    let companyData = {};
    companyData.company = company;
    companyData.isCompany = this.state.isCompanyProfile;
    companyData.userEmail = this.state.profile.email;
    let result = await ProfileService.updateCompany(companyData);
    if (!result.isSuccess) {
      console.log(result.message);
      this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: false });
    }
    else {
      console.log("Update Success:", result.isSuccess);
      this.getCompanySummaryFields(company);
      if (result.dto) {
        localStorage.setItem('currentUser', JSON.stringify(result.dto));
        currentUserSubject.next(result.dto);
      }
      this.props.toastManager.add(result.message, { appearance: 'success', autoDismiss: false });
    }
  };

  onChangeIsCompany = (e) => {
    this.setState({ isCompanyProfile: e })
  }

  onChangeCompany = (id) => {
    this.setState({ selectedCompanyId: id })
    let company = this.state.companies.filter(c => {
      return c.id == id
    })
    this.getCompanySummaryFields(company[0]);
  }

  getCompanySummaryFields = (company) => {
    let companySummaryFields = new Array;
    for (const [key, value] of Object.entries(company)) {
      if (key != 'address' && key != 'id')
        companySummaryFields.push({ name: key, value: value })
    }
    this.setState({ companySummaryFields });
  }

  onCompanyForm = () => {
    let modals = { ...this.state.modals };
    modals.companyForm = true;
    this.setState({ modals });
  }

  onSubmitRequest = () => {
    ProfileService.requestCompany(this.state.selectedCompanyId).then(res => {
      console.log(res);
      if (res.isSuccess) {
        this.props.toastManager.add(res.message, { appearance: 'success', autoDismiss: true });
        // this.setState({ profile: res.dto.user })
      }
      else {
        this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
      }
      window.location.reload(false);
    })
  }

  render() {
    return (
      <React.Fragment>
        <PageHeader
          style={{ marginTop: "0px", paddingTop: "0px" }}
          title={"User Profile"}
        />
        <Card
          style={{ boxshadow: "rgb(0 0 0 / 9%) 5px 5px 20px 6px", marginBottom: '2rem' }}>
          <Row>

            <Form
              style={{ marginTop: "25px" }}
              ref={(ref) => (this.forms.userProfile = ref)}
              name="userProfileForm"
              labelCol={{ span: 8 }}
              onFinish={this.onSubmitUserProfile}
              fields={this.state.fields}
            >
              {/* <Row><h3>Basic Info</h3></Row> */}
              <Divider orientation="left">Basic Info</Divider>
              <Row gutter={24}>
                <Col span={12}>
                  {this.getFieldsOneCol(userFields)}
                  <Col span={24}>
                    {/* <Form.Item name="jobRole" label="Job Role" rules={[{ required: true, message: "Select a Job Role" }]} >
                      <Select
                        placeholder="Select a Job Role"
                        allowClear
                      >
                        <Option value={3}>Supplier</Option>
                        <Option value={2}>Consumer</Option>
                        <Option value={1}>Admin</Option>

                        <Option value={0}>Other</Option>
                      </Select>
                    </Form.Item> */}
                  </Col>
                </Col>
                <Col span={8} offset={3}>
                  {this.state.profile &&
                    <ImageUploader
                      Url={this.state.profile.avatar}
                      Action={ProfileService.updateAvatar}
                    />
                  }
                </Col>
              </Row>
              {/* <Row><h3>Addresss</h3></Row> */}
              <Divider orientation="left">Addresss</Divider>
              <Row gutter={12}>{this.getFieldsTwoCol(addressFields)}</Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Button style={{ margin: "0 8px", }} type="primary" htmlType="submit">Update User Profile</Button>
                </Col>
              </Row>

            </Form>
            <Col span={20}>
              <Form.Item
                style={{ marginLeft: '6%', marginBottom: '0', width: '90%' }}
                label='This is a Company Profile'
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={this.state.isCompanyProfile}
                  onChange={this.onChangeIsCompany}
                />
              </Form.Item>
            </Col>
            {(this.state.isCompanyProfile /* || (this.state.profile ? this.state.profile.companyRequest != null : false) */)
              &&
              <Col span={24}>
                <Col span={24}>
                  {/* <Row><h3>Company</h3></Row> */}
                  {(this.state.currentCompany == null && (this.state.profile.companyRequest ? !this.state.profile.companyRequest.isActive : true))
                    &&
                    <Row gutter={24}>
                      <Col span={24}><Divider orientation='left' plain>Join an existing company or create one.</Divider></Col>
                      <Col span={7} offset={4} style={{ textAlign: "right", width: '' }}>
                        {/* <Form.Item name="company">     */}
                        <Select
                          defaultValue={this.state.selectedCompanyId}
                          style={{ textAlign: "center", width: '200px' }}
                          placeholder={"Select a company"}
                          options={this.state.companyOptions}

                          onChange={this.onChangeCompany}
                        >
                        </Select>
                        {/* </Form.Item> */}
                      </Col>
                      <Col span={2} style={{ textAlign: "center" }}>Or</Col>
                      <Col span={10}>
                        <Button
                          onClick={this.onCompanyForm}
                          style={{ textAlign: "center", width: '200px' }}
                        >
                          Create a Company
                        </Button>
                      </Col>
                    </Row>
                  }
                </Col>
                {console.log(this.state.profile.companyRequest)}
                {(this.state.companySummaryFields.length != 0) &&
                  <Col>
                    <Divider orientation="left" >Company Summary</Divider>
                    {(this.state.profile.companyRequest != null)
                      &&
                      <Col span={24} offset={2}>
                        <Text strong>Request Status:</Text> <Text
                          strong
                          type={this.state.profile.companyRequest.status == "Pendding" ? "warning" : this.state.profile.companyRequest.status == "Rejected" ? "danger" : "secondary"}
                        >
                          {this.state.profile.companyRequest.status}
                        </Text>
                      </Col>
                    }
                    <Form
                      style={{ marginTop: "25px" }}
                      ref={(ref) => (this.form = ref)}
                      name="companySummary"
                      labelCol={{ span: 8 }}
                      fields={this.state.companySummaryFields}
                      onFinish={this.onSubmitRequest}
                    >
                      < Row gutter={12}>{this.getFieldsTwoCol(companySummary)}</Row>
                      <Row>

                        {(this.state.currentCompany == null && (this.state.profile.companyRequest != null ? !this.state.profile.companyRequest.isActive : true))
                          &&
                          <Col span={24} offset={4}>
                            <Button style={{ margin: "0 8px", }} type="primary" htmlType="submit">Send Request</Button>
                          </Col>
                        }
                        {(this.state.currentCompany != null && this.state.roles.includes("Admin"))
                          &&
                          <Col span={24} offset={4}>
                            <Button style={{ margin: "0 8px", }} type="primary" onClick={this.onCompanyForm}>Edit Company</Button>
                          </Col>
                        }
                      </Row>

                    </Form>
                  </Col>
                }
              </Col>
            }

          </Row>
        </Card>
        {this.state.modals.companyForm &&
          <CompanyFrom
            fieldItems={companyFields}
            fields={this.state.companyFields}
            handleCancel={() => {
              let modals = { ...this.state.modals };
              modals.companyForm = false;
              this.setState({ modals });
            }}
            visible={this.state.modals.companyForm}
            getFields={this.getFieldsTwoCol}
            handleSubmit={this.onSubmitCompany}
            logo={this.state.companyLogo}
          />
        }
      </React.Fragment >

    );
  }
}

// export default UserData;

const userFields = [
  { name: "firstName", label: "First Name", required: true },
  { name: "lastName", label: "Last Name", required: true },
  { name: "phoneNumber", label: "Phone Number", required: true },
  { name: "email", label: "Email Address", required: true, disabled: true }
];

const addressFields = [
  { name: "houseNumber", label: "House Number", required: true, hidden: false, },
  { name: "street", label: "Street Address", required: true, hidden: false, },
  { name: "city", label: "City", required: true, hidden: false },
  { name: "state_District", label: "Provice/District", required: true, hidden: false },
  { name: "zipCode", label: "Zip Code", required: false, hidden: false },
  { name: "country", label: "Country", required: true, hidden: false },
  { name: "latitude", label: "Latitude", required: false, hidden: true },
  { name: "longitude", label: "Longitude", required: false, hidden: true },
];

const companyFields = [
  { name: "name", label: "Company Name", required: true },
  { name: "phone", label: "Phone Number", required: true },
  { name: "email", label: "Email Address", required: true },
  { name: "fax", label: "Fax", required: false },
  { name: "keyPersonnel", label: "Key Personnel", required: true },
  { name: "aboutUs", label: "Description", required: true },
  { name: "businessRegistration", label: "Business Reg #", required: false },
  { name: "vatOrTIN", label: "VAT Or TIN", required: true },
  { name: "website", label: "Website", required: false },
  { name: "facebookHandle", label: "Facebook Url", required: false },
  { name: "linkedInHandle", label: "LinkedIn Url", required: false },
  { name: "twitterHandle", label: "Twitter Link", required: false }
];

const companySummary = [
  { name: "name", label: "Company Name", disabled: true },
  { name: "businessRegistration", label: "Business Reg #", disabled: true },
  { name: "vatOrTIN", label: "VAT Or TIN", disabled: true },
  { name: "email", label: "Email Address", disabled: true },
  { name: "keyPersonnel", label: "Key Personnel", disabled: true },
  { name: "phone", label: "Phone Number", disabled: true },
  { name: "id", label: "id", hidden: true },
]

export const UserData = withToastManager(UserDataComponent);