import React from "react";
import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from "./helpers";

import { Typography, Row, Col } from "antd";

import { authService } from "./services";

// import { LayoutComponent } from "./components/Layouts";
import { FixedSiderLayout } from "./layouts/FixedSiderLayout";

import {
  Dashboard,
  FabNetUsers,
  AllUsers,
  UserData,
  ForgotPasswordPage, LoginPage, ResetPasswordPage,
  CompaniesPage, CompanyMembersPage, EditCompanyPage, CompanyPage,CreateCompanyPage
} from './views';

import { withToastManager } from "react-toast-notifications";

import PrivateRoute from "./components/RouterComponent/PrivateRoute";

const { Title } = Typography;

class AppComponent extends React.Component {
  async componentDidMount() {
    if (authService.currentUserValue !== null) {
      await authService.ValidateToken();
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/login" />} />

          <Route exact path="/forgot-password" exact component={ForgotPasswordPage} />
          <Route exact path="/reset-password" exact component={ResetPasswordPage} />
          <Route exact path="/login" render={(props) => <LoginPage {...props} toastManager={this.props.toastManager} />} />

          <FixedSiderLayout>

            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/profile/userdata" component={UserData} />
            <PrivateRoute exact path="/manage/users/fabNetusers" component={FabNetUsers} />
            <PrivateRoute exact path="/manage/users/allusers" component={AllUsers} />

            <PrivateRoute path="/manage/companies" component={CompaniesPage} />
            <PrivateRoute path="/manage/company/create" component={CreateCompanyPage} />
            <PrivateRoute path="/manage/company/:id/members" component={CompanyMembersPage} />
            <PrivateRoute path="/manage/company/:id/edit" component={EditCompanyPage} />
            <PrivateRoute path="/manage/company/:id/info" component={CompanyPage} />


          </FixedSiderLayout>
        </Switch>

      </Router>
    );
  }
}

const App = withToastManager(AppComponent);
export default App;




