import React, { Component } from "react";
import {
    Table,
    Space,
    Tag,
    Button, Modal, Select
} from "antd";
import { userService, companyService } from "../../services";
import { history } from "../../helpers";



const PERMISSIONS = {
    isAdmin: false,
    isCustomer: false,
    isSupplier: false
};

const PERMISSION_OPTIONS = [
    { value: "isAdmin", label: "Admin" },
    { value: "isCustomer", label: "Customer" },
    { value: "isSupplier", label: "Supplier" },
]

export class AllUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            companyOptions: []
        };
    }

    componentDidMount() {
        this.fetchUsers();
        this.getAllCompanies();
    }

    fetchUsers = () => {
        userService.getAllUsers()
            .then(result => {
                if (result.isSuccess) {
                    let users = [];
                    if (result.dto) {
                        result.dto.forEach((x, i) => {
                            users.push({
                                key: x.id,
                                name: x.firstName + " " + x.lastName,
                                email: x.email,
                                id: x.id,
                                company: x.company ? x.company.name : "",
                                companyId: x.company ? x.company.id : "",
                                permissions: x.permissions
                            })
                        });
                    }
                    this.setState({ users });
                }
                else {
                    // this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
    }

    getAllCompanies = () => {
        companyService.fetchAllCompanies().then(res => {
            let options = [];
            if (res.isSuccess) {
                res.dto.forEach((company, i) => {
                    options.push({ label: company.name, value: company.id })
                });
            }
            this.setState({ companyOptions: options })
        });
    }

    onchangeCompanydd = (companyId, userId) => {
        companyService.addMember().then(res => {
            this.fetchUsers();
        });
    }


    onchangePermission = (userId, permissions) => {
        Object.keys(PERMISSIONS).forEach(element => {
            PERMISSIONS[element] = false
        });

        permissions.forEach(p => {
            PERMISSIONS[p] = true
        });

        userService.changePermission(userId, PERMISSIONS).then(res => {
            // this.fetchUsers();
            if (res.isSuccess) {
                // console.log(res.message);
            }
            else
                console.error(res.message);
        });
    }





    forMap = (key, object) => {
        const tagElem = (
            <Tag color={object[key] ? "green" : "red"} >
                {key.replace('is', '')}
            </Tag>
        );
        return (
            <span key={key} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        );
    };

    tagRender(props) {
        const { label, value, closable, onClose } = props;
        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={"green"}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    columns = [

        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record, index) => <a>{text}</a>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record, index) => text,
        },
        {
            title: 'Company',
            dataIndex: 'company',
            render: (text, record, index) => {
                if (text != "")
                    return (<Button type="link" onClick={() => history.push(`/manage/company/${record.companyId}/info`)}>{text}</Button>)
                else {
                    return (<Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Select to assign"
                        filterOption={(input, option) => {
                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        options={this.state.companyOptions}
                        onChange={val => {
                            this.onchangeCompanydd(val, record.id)
                        }
                        }
                    ></Select>)
                }
            }
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            render: (permissions, record, index) => {

                let keys = new Array();
                for (const [key, value] of Object.entries(permissions)) {
                    console.log(`${key}: ${value}`);
                    if (value)
                        keys.push(key);
                }
                return (
                    <Select
                        mode="multiple"
                        showArrow
                        tagRender={this.tagRender}
                        defaultValue={keys}
                        style={{ minWidth: 200 }}
                        options={PERMISSION_OPTIONS}
                        onChange={vals => { this.onchangePermission(record.id, vals) }}
                    />);
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a>Remove</a>
                    <a>Deactivate</a>
                </Space>
            ),
        },
    ];





    render() {
        const { modals, users } = this.state;

        return (
            <React.Fragment>
                <div>
                    <h3>All Users</h3>
                    {/* <Button type="primary" size="small" style={{ marginTop: 10, marginBottom: 10 }} onClick={this.onCreateUser}>Create User</Button> */}
                    <Table size='small' columns={this.columns} dataSource={users} bordered />
                </div>
                <Modal>

                </Modal>
            </React.Fragment>

        );
    }
}

export default AllUsers;