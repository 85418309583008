import React, { Component } from "react";
import { Table, Space, Button, Popconfirm } from 'antd';
import { companyService, supplierService } from "../../services";
import { withToastManager } from "react-toast-notifications";
import { history } from "../../helpers";

class Companies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: []
        };
    }
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record, index) => <Button type="link" onClick={() => history.push(`/manage/company/${record.id}/info`)}>{text}</Button>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record, index) => text,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            render: (text, record, index) => text,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            render: (text, record) => record?.address?.fullAddress,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.deleteCompany(record.id)}>
                        <Button type="primary" danger href="#">Delete</Button>
                    </Popconfirm>
                    <Button type="primary" onClick={() => history.push(`/manage/company/${record.id}/edit`)}>Edit</Button>
                    <Button type="primary" onClick={() => history.push(`/manage/company/${record.id}/members`)}>View Members</Button>
                </Space>
            ),
        },
    ];


    componentDidMount() {
        this.fetchCompanies();
    }

    fetchCompanies = () => {
        companyService.fetchAllCompanies()
            .then(result => {
                if (result.isSuccess) {
                    let companies = result.dto;
                    companies.forEach((supplier, index) => supplier.key = index);
                    this.setState({ companies });
                    console.log(companies);
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    deleteCompany = (id) => {

    }

    onCreateCompany = () => history.push('/manage/company/create');

    render() {
        return (
            <div>
                <h3>COMPANIES</h3>
                <Button type="primary" size="small" style={{ marginTop: 10, marginBottom: 10 }} onClick={this.onCreateCompany}>Create New</Button>
                <Table size='small' columns={this.columns} dataSource={this.state.companies} bordered />
            </div >
        );
    }
}

export const CompaniesPage = withToastManager(Companies);