import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

export const userService = {
    getFabNetUsers,
    getAllUsers,
    createAdminUser,
    createSupplierUser,
    createUser,
    getUserData,
    changePermission
};

async function getFabNetUsers() {
    try {
        const result = await trackPromise(axios.get('/fablankaUsers/get'));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to fetch users" };
    }
}

async function getAllUsers() {
    try {
        const result = await trackPromise(axios.get('/UserManagement/all'));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to fetch users" };
    }
}

async function createAdminUser(user) {
    try {
        const result = await trackPromise(axios.post('/fablankaUsers/create', user));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to create user" };
    }
}


async function createSupplierUser(user) {
    try {
        const result = await trackPromise(axios.post('/Auth/register/suppliers', user));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to create user" };
    }
}


async function createUser(user) {
    try {
        const result = await trackPromise(axios.post('/Auth/register', user));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to create user" };
    }
}

async function getUserData() {
    try {
        const result = await trackPromise(axios.get('/profile/view'));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to fetch user data" };
    }
}

async function changePermission(userId, permissions) {
    try {
        debugger
        const result = await trackPromise(axios.patch(`UserManagement/` + userId + `/permissions`, permissions));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to change user permission." };
    }
}