import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

export const companyService = {
    createCompany,
    activateCompany,
    deactivateCompany,
    
    submitCompany,
    deleteCompany,
    fetchCompany,
    updateCompany,
    fetchAllCompanies,
    uploadLogo,
    fetchMembers,
    fetchRequests,
    addMember
    
};

async function createCompany(company) {
    try {
        let responce = await trackPromise(axios.post('/CompanyManagement/create', company))
        return (responce.data);
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to create company" };
    }
}
async function updateCompany(id, companyData) {
    try {
        return (await trackPromise(axios.put(`/CompanyManagement/${id}`, companyData))).data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to update company" };
    }
}

async function activateCompany(companyId) {
    try {
        const result = await trackPromise(axios.patch(`/CompanyManagement/${companyId}/activate`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to activate." };
    }
}
async function deactivateCompany(companyId, userId) {
    try {
        const result = await trackPromise(axios.patch(`/CompanyManagement/${companyId}/deactivate`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to deactivate." };
    }
}
async function addMember(companyId, userId) {
    try {
        const result = await trackPromise(axios.patch(`/CompanyManagement/${companyId}/addmember/${userId}`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to add member." };
    }
}

async function fetchCompany(id) {
    try {
        const result = await trackPromise(axios.get(`/CompanyManagement/${id}/info`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to get Company" };
    }
}
async function fetchMembers(companyId) {
    try {
        const result = await trackPromise(axios.get(`/CompanyManagement/${companyId}/members`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to get Supplier" };
    }
}
async function fetchRequests(companyId) {
    try {
        const result = await trackPromise(axios.get(`/CompanyManagement/${companyId}/requests`));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to get Supplier" };
    }
}
async function fetchAllCompanies() {
    try {
        const result = await trackPromise(axios.get(`/CompanyManagement/getall`));
        // if (!result.data.isSuccess) return null;
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to get Supplier" };
    }
}



async function uploadLogo(file) {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
        let responce = await trackPromise(axios.post('/AWSS3File/uploadFile?directory=test2', bodyFormData))
        return (responce.data);
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to create company" };
    }
}



async function deleteCompany() {
    try {
        return (await trackPromise(axios.delete(`/Suppliers/company`))).data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to delete customer" };
    }
}
async function submitCompany(company) {
    try {
        let responce = await trackPromise(axios.post('/Suppliers', company))
        return (responce.data);
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to create company" };
    }
}




// async function uploadLogo(file, companyId) {
//     try {
//         var bodyFormData = new FormData();
//         bodyFormData.append('file', file);
//         return (await trackPromise(axios.post("/SupplierManagement/suppliers/" + companyId + "/logo", bodyFormData))).data;
//     }
//     catch (err) {
//         return { isSuccess: false, message: "Unable to create company" };
//     }
// }


