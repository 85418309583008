import { BehaviorSubject } from 'rxjs';
import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authService = {
    login,
    logout,
    googleLogin,
    changeRole,
    forgotPassword,
    ValidateToken,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

async function login(email, password) {
    try {
        const result = await trackPromise(axios.post('/auth/login', { email, password }));
        const data = result.data;
        if (data.isSuccess) {
            localStorage.setItem('currentUser', JSON.stringify(data.dto));
            currentUserSubject.next(data.dto);
            return data.dto;
        }
    }
    catch (err) {
        return currentUserSubject.next(null);
    }
}

async function googleLogin(token) {
    try {
        const result = await trackPromise(axios.post('/auth/login/google', { token }));
        if (result.data.isSuccess) {
            localStorage.setItem('currentUser', JSON.stringify(result.data.dto));
            currentUserSubject.next(result.data.dto);
        }

        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to login with google" };
    }
}

function logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem("roles");
    currentUserSubject.next(null);
}

async function changeRole(req) {
    try {
        const result = await trackPromise(axios.put('/auth/roles', req));
        const data = result.data;
        if (data.isSuccess) {
            localStorage.setItem('currentUser', JSON.stringify(data.dto));
            currentUserSubject.next(data.dto);
            return data.dto;
        }
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to change role" };
    }
}

async function forgotPassword(email) {
    try {
        const result = await trackPromise(axios.post(`/Auth/forgot-password?email=${email}`));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to request password reset." };
    }
}

async function ValidateToken() {
    try {
        const result = await trackPromise(axios.get(`/Auth/isValidToken`));
        if (result.data.isSuccess) {
            return result.data;
        }
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to validate token" };
    }
}

async function isLogedin() {

}