import React, { useState } from 'react';
import 'antd/dist/antd.css';
import {
    Modal,
    Form,
    Input,
    Row,
    Button,
} from "antd";
import ImageUploader from "../../components/ImageUploader/ImageUploader";

import { ProfileService } from "../../services";
import { withToastManager } from "react-toast-notifications";



const CompanyFrom = ({
    fields,
    visible,
    getFields,
    fieldItems,
    userEmail,
    handleSubmit,
    handleCancel,
    logo
}) => {
    let companyForm = null;

    const handleOk = () => {
        companyForm
            .validateFields()
            .then(values => {
                handleSubmit(values)
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }
    return (
        <Modal
            title={fields.length === 0 ? "Create New Company" : "Update Company"}
            centered
            maskClosable={false}
            visible={visible}
            keyboard={false}
            width={1000}
            onOk={() => { handleOk() }}
            onCancel={() => {
                visible = false;
                handleCancel();
            }}
            footer={[
                <Button key="back" onClick={() => {
                    visible = false;
                    handleCancel();
                }}>
                    Return
                </Button>,
                <Button key="submit" type="primary" onClick={() => { handleOk() }}>
                    Submit
                </Button>,
            ]}
        >
            <ImageUploader
                Url={logo}
                Action={ProfileService.updateCompanyLogo}
            />
            <Form
                form={companyForm}
                ref={_ref => companyForm = _ref}
                name="company_form"
                labelCol={{ span: 6 }}
                fields={fields}
            >
                <Row gutter={12}>{getFields(fieldItems)}</Row>
            </Form>
        </Modal>

    );
};


export default withToastManager(CompanyFrom);
// export const CompanyFrom = withToastManager(UserDataComponent);