import React, { Component } from "react";
import "./dashboard.css";

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div>
        <h3>Admin Dashboard</h3>
      </div>
    );
  }
}

export default Dashboard;
