import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { BehaviorSubject } from "rxjs";
import { authService } from "../../services";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const location = useLocation();
    return (
        // <Route {...rest}>
        //     {authService.currentUserValue == null ?
        //         <Redirect to={{ pathname: "/login", state: { from: location } }} />
        //         :
        //         <Component />
        //     }
        // </Route>

        <Route
            {...rest}
            render={({ location, ...routeProps }) => // match.params.token is here
                authService.currentUserValue != null ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;